import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SubSubTitle from './Titles/SubSubTitle'

const PersonCard = ({ person, active, lang }) => {
  person = person.node
  const link = lang === 'de' ? '/de' : ''
  const name = person.name.replace(/\s+/g, '-').toLowerCase()

  const image = person.photo &&
    person.photo.localFile &&
    person.photo.localFile.childImageSharp && (
      <GatsbyImage
        className="h-full w-[200px] sm:w-6/12 sm:min-w-[120px] object-cover"
        image={getImage(person.photo.localFile.childImageSharp)}
        alt={person.name}
      />
    )

  return active ? (
    <Link
      to={link + '/team/' + name}
      key={person.name}
      className={`flex flex-row justify-center overflow-hidden w-full sm:w-6/12 lg:w-4/12  h-[180px] xl:h-[180px] odd:bg-gray-200 even:bg-gray-100 mb-3`}
    >
      {active ? image : null}
      <div className="flex flex-col w-full min-h-full p-3">
        <SubSubTitle>{person.name}</SubSubTitle>
        <p className="text-black break-words font-title text-md">
          {person.job_title}
        </p>
      </div>
    </Link>
  ) : (
    <article
      key={person.name}
      className={`flex flex-col justify-center overflow-hidden w-full sm:w-6/12 lg:w-4/12 xl:w-3/12 {xl:h-[180px] h-[120px] odd:bg-gray-200 even:bg-gray-100 mb-3 py-3 px-5`}
    >
      <SubSubTitle>{person.name}</SubSubTitle>
      <p className="flex-1 font-title text-md">{person.job_title}</p>
    </article>
  )
}

export default PersonCard
