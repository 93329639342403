import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/de'
import SubTitle from '../components/Titles/SubTitle'
import PersonCard from '../components/PersonCard'

const lang = 'de'
export default class ProjectsPage extends React.Component {
  render() {
    const { data, location } = this.props
    const members = data.members.edges

    const staff = members.filter(
      (m) => m.node.active === 'active' && !m.node.student_staff
    )
    const students = members.filter(
      (m) => m.node.active === 'active' && m.node.student_staff
    )
    const formerStaff = members.filter((m) => m.node.active === 'former')

    staff.sort(function (a, b) {
      return a.node.order - b.node.order
    })

    return (
      <Layout location={location}>
        <SubTitle>Mitarbeiter.</SubTitle>
        <section className="flex flex-wrap items-start justify-start">
          {staff.map((p) => (
            <PersonCard person={p} key={p.name} active lang={lang} />
          ))}
        </section>

        {students.length > 0 && (
          <>
            <SubTitle>Studenten.</SubTitle>
            <section className="flex flex-wrap items-start justify-start">
              {students.map((p) => (
                <PersonCard person={p} key={p.name} />
              ))}
            </section>
          </>
        )}

        <SubTitle>
          Ehemalige
          <br /> Mitarbeiter und Alumni.
        </SubTitle>
        <section className="flex flex-wrap items-start justify-start mb-20">
          {formerStaff.map((p) => (
            <PersonCard person={p} key={p.name} />
          ))}
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    members: allDirectusMember {
      edges {
        node {
          name
          job_title
          order
          email
          active
          student_staff
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
